import { useCallback, useEffect, useState } from 'react';

import { useWindowEvent } from './useWindowEvent';

export type StorageType = 'localStorage' | 'sessionStorage';

export type StorageProperties<T> = {
  /** Storage key */
  key: string;

  /** Default value that will be set if value is not found in storage */
  defaultValue?: T;

  /** If set to true, value will be update is useEffect after mount */
  getInitialValueInEffect?: boolean;

  /** Function to serialize value into string to be save in storage */
  serialize?: (value: T) => string;

  /** Function to deserialize string value from storage to value */
  deserialize?: (value: string | undefined) => T;
};

function serializeJSON<T>(value: T) {
  try {
    return JSON.stringify(value);
  } catch {
    throw new Error('Failed to serialize the value');
  }
}

function deserializeJSON(value: string | undefined) {
  try {
    return value && JSON.parse(value);
  } catch {
    return value;
  }
}

function createStorageHandler(type: StorageType) {
  const getItem = (key: string) => {
    try {
      return window[type].getItem(key);
    } catch {
      console.warn('use-local-storage: Failed to get value from storage, localStorage is blocked');
      return null;
    }
  };

  const setItem = (key: string, value: string) => {
    try {
      window[type].setItem(key, value);
    } catch {
      console.warn('use-local-storage: Failed to set value to storage, localStorage is blocked');
    }
  };

  const removeItem = (key: string) => {
    try {
      window[type].removeItem(key);
    } catch {
      console.warn('use-local-storage: Failed to remove value from storage, localStorage is blocked');
    }
  };

  return { getItem, setItem, removeItem };
}

export function createStorage<T>(type: StorageType) {
  const { getItem, setItem, removeItem } = createStorageHandler(type);

  return function useStorage({
    key,
    defaultValue,
    getInitialValueInEffect = true,
    deserialize = deserializeJSON,
    serialize = (value: T) => serializeJSON(value),
  }: StorageProperties<T>) {
    const readStorageValue = useCallback(
      (skipStorage?: boolean): T => {
        let storageBlockedOrSkipped;
        try {
          storageBlockedOrSkipped =
            typeof globalThis === 'undefined' || !(type in globalThis) || window[type] === null || !!skipStorage;
        } catch {
          storageBlockedOrSkipped = true;
        }

        if (storageBlockedOrSkipped) {
          return defaultValue as T;
        }

        const storageValue = getItem(key);
        return storageValue === null ? (defaultValue as T) : deserialize(storageValue);
      },
      [key, defaultValue, deserialize]
    );

    const [value, setValue] = useState<T>(readStorageValue(getInitialValueInEffect));

    const setStorageValue = useCallback(
      (val: T | ((prevState: T) => T)) => {
        if (val instanceof Function) {
          setValue((current) => {
            const result = val(current);
            setItem(key, serialize(result));
            globalThis.dispatchEvent(new CustomEvent(type, { detail: { key, value: val(current) } }));
            return result;
          });
        } else {
          setItem(key, serialize(val));
          globalThis.dispatchEvent(new CustomEvent(type, { detail: { key, value: val } }));
          setValue(val);
        }
      },
      [key, serialize]
    );

    const removeStorageValue = useCallback(() => {
      removeItem(key);
      globalThis.dispatchEvent(new CustomEvent(type, { detail: { key, value: defaultValue } }));
    }, [defaultValue, key]);

    useWindowEvent('storage', (event) => {
      if (event.storageArea === window[type] && event.key === key) {
        setValue(deserialize(event.newValue ?? undefined));
      }
    });

    useWindowEvent(type, (event) => {
      if (event.detail.key === key) {
        setValue(event.detail.value);
      }
    });

    useEffect(() => {
      if (defaultValue !== undefined && value === undefined) {
        setStorageValue(defaultValue);
      }
    }, [defaultValue, value, setStorageValue]);

    useEffect(() => {
      if (getInitialValueInEffect) {
        setValue(readStorageValue());
      }
    }, [getInitialValueInEffect, readStorageValue]);

    return [value === undefined ? defaultValue : value, setStorageValue, removeStorageValue] as [
      T,
      (val: T | ((prevState: T) => T)) => void,
      () => void,
    ];
  };
}
export function useLocalStorage<T = string>(props: StorageProperties<T>) {
  return createStorage<T>('localStorage')(props);
}

import '@amzn/awsui-global-styles/polaris.css';
import { I18nProvider, importMessages } from '@amzn/awsui-components-react/polaris/i18n';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider, type PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import { Amplify } from 'aws-amplify';
import { lazy, StrictMode, Suspense, useEffect, useLayoutEffect } from 'react';
import { createRoot } from 'react-dom/client';

import { getResourceConfig } from '@/backend';
import { useVisualModeControls } from '@/common/hooks/content-display-controls';
import { useCognitoAuth } from '@/common/hooks/useAuth';
import { getErrorMessageFromException } from '@/common/utility';
import FullPageSpinner from '@/components/common/FullPageSpinner';
import ElevateAppRouter from '@/router';
import appStore from '@/stores/app-store';
import { AwsRumProvider, useAwsRum } from '@/stores/AwsRumContext';
import { SessionConfigProvider, useSession } from '@/stores/SessionConfig';
import { getSupportedLocale } from '@/utilities/i18n';

const ElevateErrorBoundary = lazy(() => import('@/components/app/ElevateErrorBoundary'));

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (ex, query) => {
      if (!query.meta?.errorMessage) return;
      appStore.notify({
        content: getErrorMessageFromException(ex),
        header: String(query.meta?.errorMessage),
        type: 'error',
      });
    },
    onSuccess: (_, query) => {
      if (!query.meta?.onSuccess) return;
      query.meta.onSuccess();
    },
  }),
  mutationCache: new MutationCache({
    onError: (ex, _variables, _context, mutation) => {
      if (!mutation.meta?.errorMessage) return;
      appStore.notify({
        content: getErrorMessageFromException(ex),
        header: String(mutation.meta?.errorMessage),
        type: 'error',
      });
    },
    onSuccess: (_data, _variables, _context, mutation) => {
      if (!mutation.meta?.successMessage) return;
      appStore.notify({ header: String(mutation.meta?.successMessage), type: 'error' });
    },
  }),
  defaultOptions: {
    queries: {
      gcTime: 3 * 60 * 1000, // cache for 10 min
      retry: 1, // only retry a failure once
      structuralSharing: true, // If performance is slow on large responses set this to false
      refetchOnWindowFocus: false, // This doesn't interact well w/ the Polaris components. If this is active, most interactions with a page will trigger all the queries to refetch
    },
    mutations: {
      retry: 1, // only retry a failure once
      gcTime: 0, // Don't cache mutation responses ever
    },
  },
});

/**
 * Sets the global log level for the Amplify library and `logger` instances.
 * @see https://docs.amplify.aws/lib/utilities/logger/q/platform/js/
 */
globalThis.LOG_LEVEL = globalThis.LOG_LEVEL ?? import.meta.env.ELEVATE_FF_LOG_LEVEL ?? 'DEBUG';

Amplify.configure(getResourceConfig());

const locale = getSupportedLocale();
const messages = await importMessages(locale);

const documentRoot = document.querySelector('#root');
if (documentRoot === null) {
  throw new Error('#root is not found in index.html.');
}

const root = createRoot(documentRoot);

broadcastQueryClient({ queryClient, broadcastChannel: 'amzn-elevate' });

const localStoragePersister = createSyncStoragePersister({ storage: globalThis.localStorage });

// Special query keys that we want to cache between page loads
// These should be specific to stuff that we need to display the page
// Use sparingly. We can only cache 10 Mb of data
// https://en.wikipedia.org/wiki/Web_storage#Storage_size
const persistedQueryKeys = new Set(['profileData', 'collaborations']);
const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  maxAge: 5 * 60 * 1000,
  persister: localStoragePersister,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey }) => queryKey.some((key) => persistedQueryKeys.has(String(key))),
  },
};

function Wrapper(): React.ReactElement {
  const { initialize: initContentDensity } = useVisualModeControls();
  const { initialize: initVisualMode } = useVisualModeControls();
  const { finishedLoading, sessionObject } = useSession();
  const { hasElevateAccess } = useCognitoAuth();
  const awsRum = useAwsRum();

  useEffect(() => {
    if (sessionObject.profileID) awsRum?.addSessionAttributes({ profileId: sessionObject.profileID });
  }, [awsRum, sessionObject.profileID]);

  useLayoutEffect(() => {
    initContentDensity();
    initVisualMode();
  }, [initContentDensity, initVisualMode]);

  if (!finishedLoading) {
    return <FullPageSpinner />;
  } else if (hasElevateAccess) {
    return <ElevateAppRouter />;
  } else {
    const NoAccessLandingPage = lazy(() => import('@/components/LandingPages/NoAccessLandingPage'));

    return (
      <Suspense fallback={<FullPageSpinner />}>
        <NoAccessLandingPage />
      </Suspense>
    );
  }
}

root.render(
  <StrictMode>
    <AwsRumProvider>
      <ElevateErrorBoundary>
        <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
          {import.meta.env.MODE === 'development' && <ReactQueryDevtools initialIsOpen={true} />}
          <SessionConfigProvider>
            <I18nProvider locale={locale} messages={messages}>
              <Wrapper />
            </I18nProvider>
          </SessionConfigProvider>
        </PersistQueryClientProvider>
      </ElevateErrorBoundary>
    </AwsRumProvider>
  </StrictMode>
);

import { amplifyFetch } from '@/common/data-provider';
import type { Nullable, OptionalString } from '@/common/models';
import { cleanPermissionGroup, dedupeList } from '@/common/utility';
import type { BarRaiserDefinition } from '@/components/BarRaiser/models';
import {
  getAllOrgCollaborations,
  getOrgBarRaisers,
  getOrgRoleGroups as getOrgRoleGroupsQuery,
  getOrgRoleLDAPGroups,
  listBRITProfiles,
  listOrganizationsSimple,
} from '@/graphql/queries';
import {
  gql,
  type ModelOrgRoleLdapGroupsFilterInput,
  type ModelRolePermissionGroupFilterInput,
  OrganizationStatus,
  ProfileStatus,
  Roles,
} from '@/graphql/types';
import type { CollaborationItem } from '@/models';
import featureFlags from '@/utilities/feature-flags';

async function getRoleGroupsClassic(
  orgId: string,
  roles?: Nullable<Roles | Roles[]>
): Promise<{ group: string; role: Roles }[]> {
  let filter: Nullable<ModelOrgRoleLdapGroupsFilterInput>;
  if (roles) filter = { or: (Array.isArray(roles) ? roles : [roles]).map((v) => ({ role: { eq: v } })) };
  if (filter?.or?.length === 1) filter = filter.or[0];
  const document = gql(getOrgRoleLDAPGroups);
  const data = await amplifyFetch({ document, variables: { id: orgId, roleLDAPGroupsFilter: filter || null } });
  return data.getOrganization.roleLDAPGroups?.items ?? [];
}

async function getRoleGroupsPlatform(
  orgId: string,
  roles?: Nullable<Roles | Roles[]>
): Promise<{ group: string; role: Roles }[]> {
  let filter: Nullable<ModelRolePermissionGroupFilterInput>;
  if (roles) filter = { or: (Array.isArray(roles) ? roles : [roles]).map((v) => ({ role: { eq: v } })) };
  if (filter?.or?.length === 1) filter = filter.or[0];
  const document = gql(getOrgRoleGroupsQuery);
  const data = await amplifyFetch({ document, variables: { id: orgId, roleGroupsFilter: filter || null } });
  return data.getOrganization.roleGroups?.items ?? [];
}

export async function getOrgRoleGroups(
  orgId: OptionalString,
  roles?: Nullable<Roles | Roles[]>
): Promise<Map<Roles, string>> {
  if (!orgId) return new Map();
  const fn = featureFlags.PERMISSION_GROUP_MODEL.value === 'classic' ? getRoleGroupsClassic : getRoleGroupsPlatform;
  const items = await fn(orgId, roles);
  return new Map(items.map((item) => [item.role, cleanPermissionGroup(item.group)]));
}

export async function getOrgAdminGroup(orgId: OptionalString): Promise<OptionalString> {
  let orgRoleToGroup = new Map<Roles, string>();
  try {
    orgRoleToGroup = await getOrgRoleGroups(orgId, Roles.ORG_ADMIN);
  } catch (ex) {
    console.warn('Hit error retrieving org admin LDAP group name', ex);
  }
  return orgRoleToGroup.values().next().value as OptionalString;
}

export async function getOrgIdNameMapping(): Promise<Map<string, string>> {
  const data = await amplifyFetch({
    document: gql(listOrganizationsSimple),
    variables: { filter: { status: { eq: OrganizationStatus.ACTIVE } } },
  });
  return new Map((data.listOrganizations.items ?? []).map((item) => [item.id, item.name]));
}

export async function getCollaborationDefinitions(orgId: OptionalString): Promise<CollaborationItem[]> {
  if (!orgId) return [];
  const document = gql(getAllOrgCollaborations);
  const data = await amplifyFetch({ document, variables: { id: orgId } });
  const inbound = data.getOrganization.orgCollaborationsInbound?.items ?? [];
  const outbound = data.getOrganization.orgCollaborationsOutbound?.items ?? [];
  const all = [...inbound, ...outbound];
  return all.map((item) => ({
    ...item,
    barRaisers: item.barRaisers ?? false,
    originatingOrgID: 'originatingOrgID' in item ? item.originatingOrgID : null,
    targetOrgID: 'targetOrgID' in item ? item.targetOrgID : null,
    originParticipants: item.originParticipants?.items ?? [],
    targetParticipants: item.targetParticipants?.items ?? [],
  }));
}

export async function getBarRaisers(orgId: OptionalString): Promise<BarRaiserDefinition[]> {
  if (!orgId) return [];
  const document = gql(getOrgBarRaisers);
  const results = await amplifyFetch({
    document,
    variables: { id: orgId },
    onFetchNextToken: (v) => v.getOrganization.barRaisers?.nextToken || null,
  });
  const barRaisers: BarRaiserDefinition[] = results.flatMap((result) =>
    (result.getOrganization.barRaisers?.items ?? [])
      .filter((item) => !!item.profile)
      .map(
        (item): BarRaiserDefinition => ({
          id: item.profile!.id,
          alias: item.profile!.alias,
          email: item.profile?.email,
          name: item.profile?.name ?? '',
          level: item.profile?.level,
          levelFilter: item.profile?.level ? `L${item.profile.level}` : undefined,
          orgId: result.getOrganization.id,
          orgName: result.getOrganization.name,
          recordId: item.id,
          status: item.profile?.status ? ProfileStatus[item.profile.status] : ProfileStatus.ACTIVE,
          title: item.profile?.title,
          updatedAt: item.updatedAt,
        })
      )
  );
  return dedupeList(barRaisers, 'alias');
}

export async function getBRITs(orgId: OptionalString): Promise<BarRaiserDefinition[]> {
  if (!orgId) return [];
  const document = gql(listBRITProfiles);
  const results = await amplifyFetch({
    document,
    variables: { orgID: orgId },
    onFetchNextToken: (v) => v.listProfilesByBrStatus.nextToken || null,
  });
  const barRaisers: BarRaiserDefinition[] = results.flatMap((result) =>
    (result.listProfilesByBrStatus.items ?? [])
      .filter((item) => !!item)
      .map(
        (item): BarRaiserDefinition => ({
          orgId,
          id: item.id,
          alias: item.alias,
          email: item.email,
          name: item.name ?? '',
          level: item.level,
          levelFilter: item.level ? `L${item.level}` : undefined,
          orgName: '',
          recordId: item.id,
          status: item.status ? ProfileStatus[item.status] : ProfileStatus.ACTIVE,
          title: item.title,
          updatedAt: item.updatedAt,
        })
      )
  );
  return dedupeList(barRaisers, 'alias');
}

import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import * as Auth from 'aws-amplify/auth';
import { ConsoleLogger } from 'aws-amplify/utils';

import { QueryKeys } from '@/backend/queryKeys';
import type { Nullable } from '@/common/models';
import { CACHE_KEY_AUTH_START_PATH } from '@/utilities/constants';
import type { AuthGroup } from '@/utilities/permissions';

const logger = new ConsoleLogger('CognitoAuth');

const RETRYABLE_ERRORS = new Set(['NoSessionFoundException', 'UserUnAuthenticatedException', 'NotAuthorizedException']);
const FEDERATED_IDENTITY_PROVIDER = 'AmazonFederate';

type IDTokenIdentity = {
  userId: string;
  providerName: 'AmazonFederate';
  providerType: 'OIDC';
  issuer: string | null;
  primary: 'true' | 'false';
  dateCreated: string;
};

type AccessTokenPayload = Auth.JWT['payload'] & {
  client_id: string;
  token_use: 'access';
  username: string;
};

type IDTokenPayload = Auth.JWT['payload'] & {
  'cognito:groups': (string | AuthGroup)[];
  email: string;
  family_name: string;
  given_name: string;
  identities: IDTokenIdentity[];
  isElevateAdmin: 'true' | 'false';
  token_use: 'id';
  validElevateUser: 'true' | 'false';
};

type CognitoTokens = {
  accessToken: { payload: AccessTokenPayload; jwtToken: string };
  idToken: { payload: IDTokenPayload; jwtToken: string };
};

export type ElevateAuthSession = {
  username: string;
  firstName: string;
  lastName: string;
  groups: string[];
  isElevateAdmin: boolean;
  validElevateUser: boolean;
};

function isAccessTokenPayload(v: Nullable<Auth.JWT['payload']>): v is AccessTokenPayload {
  return !!v && 'token_use' in v && v.token_use === 'access';
}

function isIDTokenPayload(v: Nullable<Auth.JWT['payload']>): v is IDTokenPayload {
  return !!v && 'token_use' in v && v.token_use === 'id';
}

export function signOut() {
  try {
    void Auth.signOut();
  } catch {
    /* empty */
  }
}

export const refreshSession = async () => Auth.fetchAuthSession({ forceRefresh: true });

function parseTokenData(session: Nullable<CognitoTokens>): Nullable<ElevateAuthSession> {
  logger.debug('[start] parse cognito token data', session);
  if (!session) {
    logger.info('[end] parse cognito token data - no session data to parse');
    return null;
  }
  const idToken = session.idToken.payload;
  const username = idToken.identities[0].userId;
  const firstName = idToken.given_name;
  const lastName = idToken.family_name;
  const groups = idToken['cognito:groups'];
  const isElevateAdmin = Boolean(idToken.isElevateAdmin || 'false');
  const validElevateUser = Boolean(idToken.validElevateUser || 'false');
  const result = { username, firstName, lastName, groups, isElevateAdmin, validElevateUser };
  logger.debug('[end] parse cognito token data', result);
  return result;
}

async function loadSession(): Promise<Nullable<CognitoTokens>> {
  try {
    // We don't want the output from this, but it throws a more reasonable error when the user isn't signed in
    await Auth.getCurrentUser();
    // Auth.fetchAuthSession() will automatically refresh the accessToken and idToken if expired.
    const { tokens } = await Auth.fetchAuthSession();
    // If a session is returned, we are authenticated, so set the status in the cache
    if (isIDTokenPayload(tokens?.idToken?.payload) && isAccessTokenPayload(tokens?.accessToken?.payload)) {
      return {
        accessToken: { payload: tokens?.accessToken?.payload, jwtToken: tokens?.accessToken.toString() },
        idToken: { payload: tokens?.idToken?.payload, jwtToken: tokens?.idToken.toString() },
      };
    }
    return null;
  } catch (ex) {
    if (ex instanceof Error && RETRYABLE_ERRORS.has(ex.name)) {
      /*
       The oAuth flow can start from any page, but drops the user on the homepage upon completion. Not great.
       Store the current location, so it can be restored after auth completes.
      */
      globalThis.dispatchEvent(new CustomEvent(CACHE_KEY_AUTH_START_PATH, { detail: globalThis.location.pathname }));
      await Auth.signInWithRedirect({ provider: { custom: FEDERATED_IDENTITY_PROVIDER } });
      /*
       `signInWithRedirect` starts the oAuth process, and returns before the session is finalized.
       We return null and the app handles this downstream.
      */
      return null;
    }
    logger.error(ex);
  }
  throw new Error('User is not authenticated!');
}

export function useCognitoSession(): UseQueryResult<Nullable<ElevateAuthSession>> {
  return useQuery({
    queryKey: QueryKeys.cognito,
    queryFn: loadSession,
    staleTime: Infinity,
    //refetchIntervalInBackground: true,
    select: parseTokenData,
  });
}

/* eslint-disable prettier/prettier */
import { Roles } from '@amzn/elevate-graphql/types';

import {
  auth_actions as actions,
} from './constants';
import { Nullable, OptionalString } from '@/common/models';

const CONDITIONAL_AUTH_ALLOWED_ACTIONS: Set<AuthAction> = new Set([actions.EDIT_SCHEDULED_LOOP, actions.EDIT_SCHEDULED_PHONE_SCREEN]);

// This only works for values that we can promise don't change at runtime
// which means that an object must be casted as const with typescript
type ValueOf<T> = T[keyof T];
// Luckily. They're actually used as constants in the code base.

// So we can use the objects to create strong types of only the valid values
// These will automatically update when the constant is modified.
export type AuthAction = ValueOf<typeof actions>;
export type AuthGroup = `${ValueOf<typeof Roles>}`;
export type PermissionMatrix = Map<AuthAction, Set<AuthGroup>>;
const permissionMatrix: PermissionMatrix = new Map();

// Organizations
permissionMatrix.set(actions.VIEW_ORGANIZATIONS, new Set([Roles.ORG_ADMIN]));
permissionMatrix.set(actions.CREATE_ORGANIZATION, new Set([]));
permissionMatrix.set(actions.EDIT_ORGANIZATION, new Set([]));

// Candidates
permissionMatrix.set(actions.VIEW_CANDIDATE_NAME, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));

// Comments
permissionMatrix.set(actions.CREATE_EVENT_COMMENT, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD, Roles.INTERVIEWER]));
permissionMatrix.set(actions.DELETE_COMMENT, new Set([Roles.ORG_ADMIN]));
permissionMatrix.set(actions.VIEW_EVENT_COMMENT, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD, Roles.INTERVIEWER]));
permissionMatrix.set(actions.VIEW_FEEDBACK, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));

// Phone Screens
permissionMatrix.set(actions.CREATE_PHONE_SCREEN, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));
permissionMatrix.set(actions.EDIT_ANY_ORG_PHONE_SCREEN, new Set([Roles.ORG_ADMIN]));
permissionMatrix.set(actions.EDIT_SCHEDULED_PHONE_SCREEN, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));
permissionMatrix.set(actions.FIND_AVAILABLE_INTERVIEWER, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));
permissionMatrix.set(actions.VIEW_PHONE_SCREEN_SHADOW_OPPORTUNITIES, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.INTERVIEWER]));

// Loops
permissionMatrix.set(actions.CREATE_LOOP_EVENT, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));
permissionMatrix.set(actions.VIEW_BAR_RAISERS, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));
permissionMatrix.set(actions.MANAGE_BAR_RAISERS, new Set([Roles.ORG_ADMIN]));
permissionMatrix.set(actions.EDIT_ANY_ORG_LOOP, new Set([Roles.ORG_ADMIN]));
permissionMatrix.set(actions.EDIT_SCHEDULED_LOOP, new Set([Roles.RECRUITER, Roles.ORG_ADMIN]));

permissionMatrix.set(actions.LEAD_EVENT, new Set([Roles.EVENT_LEAD]));

// Not yet placed
permissionMatrix.set(actions.MANAGE_INTERVIEWERS, new Set([Roles.ORG_ADMIN]));

// Manager Team View
permissionMatrix.set(actions.VIEW_TEAM_MANAGER_VIEW, new Set([Roles.ORG_ADMIN, Roles.EVENT_LEAD, Roles.INTERVIEWER]));
permissionMatrix.set(actions.LOOKUP_INTERVIEWERS, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD]));

// Interviewer management
permissionMatrix.set(actions.INTERVIEWER_MANAGEMENT, new Set([Roles.RECRUITER, Roles.ORG_ADMIN, Roles.EVENT_LEAD, Roles.CALIBRATION_SHEPHERD]));

function isAuthGroup(value: OptionalString): value is AuthGroup {
  return !!value && Roles[value] !== undefined;
}

function checkConditionalAuth(action: AuthAction, isAllowedCallback?: () => boolean): boolean {
  return CONDITIONAL_AUTH_ALLOWED_ACTIONS.has(action) && (isAllowedCallback?.() ?? false);
}

function checkPermissionMatrix(
  action: AuthAction,
  group: Nullable<AuthGroup | string>,
  matrix = permissionMatrix
): boolean {
  // If we were given something that isn't even included in the possible groups
  if (!isAuthGroup(group)) return false;
  // get the permission groups for the supplied auth action
  const permissionGroups = matrix.get(action);

  // if the permission action does not have a group associated
  // default return false
  if (permissionGroups === undefined) return false;

  // otherwise check if the group is allowed to do that action
  return permissionGroups.has(group);
}

export { permissionMatrix, isAuthGroup, checkConditionalAuth, checkPermissionMatrix };

// Unit Testing
if (import.meta.vitest) {
  const { describe, it, expect, expectTypeOf } = import.meta.vitest;

  describe('isAuthGroup', () => {
    const values: string[] = Object.values(Roles);
    it('Should return true for all values of roles', () => {
      for (const value of values) {
        expect(isAuthGroup(value)).toBe(true);
      }
    });

    it('Should return false for values that are not in roles', () => {
      expect(isAuthGroup('Potato')).toBe(false);
    });

    it('Should narrow the type of an unknown string', () => {
      for (const value of values) {
        if (isAuthGroup(value)) expectTypeOf(value).toEqualTypeOf<AuthGroup>();
      }
    });
  });

  describe('checkPermissionMatrix', () => {
    it("Should return false when matrix doesn't a permission ", () => {
      const matrix: PermissionMatrix = new Map();
      const result = checkPermissionMatrix('DELETE_COMMENT', 'EVENT_LEAD', matrix);
      expect(result).toBe(false);
    });

    it('Should return false when the matrix does have a permission in it, but not the specified group', () => {
      const matrix: PermissionMatrix = new Map();
      matrix.set('CREATE_LOOP_EVENT', new Set());
      const result = checkPermissionMatrix('CREATE_LOOP_EVENT', 'EVENT_LEAD', matrix);
      expect(result).toBe(false);
    });

    it('Should return true when the matrix has the permission, and the group', () => {
      const matrix: PermissionMatrix = new Map();
      matrix.set('CREATE_LOOP_EVENT', new Set(['EVENT_LEAD']));
      const result = checkPermissionMatrix('CREATE_LOOP_EVENT', 'EVENT_LEAD', matrix);
      expect(result).toBe(true);
    });
  });
}
